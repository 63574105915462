
  .billing {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: teal;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    color: white;
  }

  h1{
    text-align: center;
  }

  .planTitle {
    font-size: 20px;
  }

  .form {
      width: 95%;
      max-width: 25rem;
      margin: 2rem auto;
    }

    .control {
      margin-bottom: 0.5rem;
    }

    .control label {
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: white;
      display: block;
    }

    .control input {
      display: block;
      font: inherit;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #38015c;
      padding: 0.25rem;
      background-color: #f7f0fa;
    }

    .control select {
      display: block;
      font: inherit;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #38015c;
      padding: 0.25rem;
      background-color: #f7f0fa;
    }
  .actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #9f5ccc;
    border: 1px solid #9f5ccc;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }

  .actions button:hover {
    background-color: #873abb;
    border-color: #873abb;
  }
