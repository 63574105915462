.dashboard {
    margin: 3rem auto;
    text-align: center;
  }
  
  .dashboard h1 {
    font-size: 5rem;
  }

  .section {
      border: 1px;
      border-color: blue;
  }
  
  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #9f5ccc;
    border: 1px solid #9f5ccc;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }