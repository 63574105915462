@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}

.MainNavigation_header__2lqQ1 {
    width: 100%;
    height: 5rem;
    background-color: teal;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
  }

  .MainNavigation_logo__1TgMx {
    font-family: 'Lato', sans-serif;
    font-size: 2rem;
    color: white;
    margin: 0;
  }

  .MainNavigation_header__2lqQ1 ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }

  .MainNavigation_header__2lqQ1 li {
    margin: 0 1rem;
  }

  .MainNavigation_header__2lqQ1 a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

  .MainNavigation_header__2lqQ1 button {
    font: inherit;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
  }

  .MainNavigation_header__2lqQ1 a:hover {
    color: #c291e2;
  }

  .MainNavigation_header__2lqQ1 button:hover {
    background-color: #c291e2;
    color: #38015c;
  }

.UserProfile_profile__gQOnH {
    margin: 3rem auto;
    text-align: center;
  }
  
  .UserProfile_profile__gQOnH h1 {
    font-size: 5rem;
  }

  .Billing_billing__Pa21X {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border-radius: 6px;
    background-color: teal;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    color: white;
  }

  h1{
    text-align: center;
  }

  .Billing_planTitle__2Fm-b {
    font-size: 20px;
  }

  .Billing_form__2xj1V {
      width: 95%;
      max-width: 25rem;
      margin: 2rem auto;
    }

    .Billing_control__2LoJH {
      margin-bottom: 0.5rem;
    }

    .Billing_control__2LoJH label {
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: white;
      display: block;
    }

    .Billing_control__2LoJH input {
      display: block;
      font: inherit;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #38015c;
      padding: 0.25rem;
      background-color: #f7f0fa;
    }

    .Billing_control__2LoJH select {
      display: block;
      font: inherit;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #38015c;
      padding: 0.25rem;
      background-color: #f7f0fa;
    }
  .Billing_actions__3yPpa button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #9f5ccc;
    border: 1px solid #9f5ccc;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }

  .Billing_actions__3yPpa button:hover {
    background-color: #873abb;
    border-color: #873abb;
  }

.Dashboard_dashboard__BewSV {
    margin: 3rem auto;
    text-align: center;
  }
  
  .Dashboard_dashboard__BewSV h1 {
    font-size: 5rem;
  }

  .Dashboard_section__3d4G1 {
      border: 1px;
      border-color: blue;
  }
  
  .Dashboard_actions__1athj {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Dashboard_actions__1athj button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #9f5ccc;
    border: 1px solid #9f5ccc;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
  }
.Documentation_container__1UYzN {
    background-color: lightcyan;
    padding: 2rem;
}

.Documentation_text__3vhrX {
    text-align: center;
}


.StartingPageContent_starting__kwCvL {
    margin: 3rem auto;
    text-align: center;
  }
  
  .StartingPageContent_starting__kwCvL h1 {
    font-size: 5rem;
  }
